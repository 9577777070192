import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import s from "./projectStyles.module.scss"

import BJGif from "../images/projects-page/bjonthedaily.gif"
import ArtworkGif from "../images/projects-page/artwork.gif"
import MaeveGif from "../images/projects-page/maeve-lights.gif"
import VideosGif from "../images/projects-page/music-videos.gif"
import TKAKGif from "../images/projects-page/tokillaking.gif"
import WebGif from "../images/projects-page/web-design.gif"

class OtherProjects extends Component {
   constructor(props) {
      super(props)
   }
   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "Check out some of my recent work here.",
               link: "about-me",
            },
            { text: "Or click here to get in touch with me.", link: "contact" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <div className={`mainContainer ${s.projectsPage}`}>
            <Layout
               title="Other Projects"
               superTitle="Mr BJ Jackson"
               pageStyles={s}
               animationStatus={animationStatus}
               footerContent={footerContent}
               isProjectPage={false}
            >
               <div className="projectsHolder">
                  <div className="project">
                     <Link to="/projects/bjonthedaily">
                        <img
                           className="projectImg"
                           src={BJGif}
                           alt="BJ On The Daily"
                        />
                        <h3 className="projectTitle">BJ On The Daily</h3>
                        <div className="projectSubtitle">
                           Daily songwriting exersize started in January 2019.
                           <br />
                           90+ original compositions.
                        </div>
                     </Link>
                  </div>{" "}
                  <div className="project">
                     <Link to="projects/web-development">
                     <img
                        className="projectImg"
                        src={WebGif}
                        alt="Web design & Development"
                     />
                     <h3 className="projectTitle">Website Development</h3>
                     <div className="projectSubtitle">
                        It’s my other hustle.
                     </div>
                     </Link>
                  </div>{" "}
                  <div className="project">
                     <Link to="/projects/electronics">
                        <img
                           className="projectImg"
                           src={MaeveGif}
                           alt="“Maeve” Bespoke Portable Lighting Rig"
                        />
                        <h3 className="projectTitle">
                           “Maeve” Bespoke Portable Lighting Rig
                        </h3>
                        <div className="projectSubtitle">
                           Custom built MIDI controlled lighting rig I designed
                           and built for the Maeve stage show.
                        </div>
                     </Link>
                  </div>{" "}

                  <div className="project">
                     <Link to="/projects/music-videos">
                        <img
                           className="projectImg"
                           src={VideosGif}
                           alt="Music Video Direction"
                        />
                        <h3 className="projectTitle">Music Video Direction</h3>
                        <div className="projectSubtitle">
                           Including “Howling” - a live action stop motion video
                           comprised of over 5000 individual photographs
                        </div>
                     </Link>
                  </div>{" "}

                  <div className="project">
                     {/* <Link to="projects/design-and-artwork"> */}
                     <img
                        className="projectImg"
                        src={ArtworkGif}
                        alt="Design and Artwork"
                     />
                     <h3 className="projectTitle">Design and Artwork</h3>
                     <div className="projectSubtitle">
                        Poster, album artwork design and some motion graphics
                        stuff.
                        <br /> Page coming soon.
                     </div>
                     {/* </Link> */}
                  </div>{" "}
                  <div className="project">
                     {/* <Link to="projects/to-kill-a-king"> */}
                     <img
                        className="projectImg"
                        src={TKAKGif}
                        alt="To Kill A King"
                     />
                     <h3 className="projectTitle">To Kill A King</h3>
                     <div className="projectSubtitle">
                        A five-piece indie rock band that kept me busy for most
                        of my twenties.
                        <br /> Page coming soon.
                     </div>
                     {/* </Link> */}
                  </div>
               </div>
            </Layout>
         </div>
      )
   }
}

export default OtherProjects
